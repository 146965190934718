import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { List, Expander, Row, Table, Attributes, InfoIcon, Tabs } from './components';
import { getGroupsCurl, getGroupsJava, getGroupsJavascript, getGroupsNode, getGroupsPerl, getGroupsPython, getGroupsPhp, getGroupsRuby, getGroupsGo, getGroupsC, getGroupsVB, getGroupsGroovy, getGroupsObjectiveC, getGroupsSwift, addMembersGroupCurl, addMembersGroupJava, addMembersGroupJavascript, addMembersGroupNode, addMembersGroupPerl, addMembersGroupPython, addMembersGroupPhp, addMembersGroupRuby, addMembersGroupGo, addMembersGroupC, addMembersGroupVB, addMembersGroupGroovy, addMembersGroupObjectiveC, addMembersGroupSwift } from 'requestExamples/groups';
export const endpoints = [{
  param: 'GET',
  value: '/nodes/pth/{path}',
  link: '#get-group-information'
}, {
  param: 'PUT',
  value: '/nodes/grp/{path}/groups/{groupType}/{email}',
  link: '#add-members-to-a-group'
}];
export const baseUrlGet = [{
  param: 'GET',
  value: '/nodes/grp/{path}'
}];
export const baseUrlAdd = [{
  param: 'PUT',
  value: '/nodes/grp/{path}/groups/{groupType}/{email}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  baseUrlGet,
  baseUrlAdd,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "groups"
    }}>{`Groups`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			SmartVault allows more than one user to have access to an account at the same time. In order for this multi
			user design to work properly, it uses groups to organize users.
			<br />
			<br />
			Groups can be managed through the path <span style={{
            "color": "#00bf99"
          }}>/nodes/grp</span>. Each group has a set of
			permissions that will apply to all the members within that group.
			<br />
			<br />A user will only have the permissions related to the group or groups they are members of.
		</p>
	</div>
	<Table title="endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <br />
    <h2 {...{
      "id": "the-group-object"
    }}>{`The group object`}</h2>
    <Expander title="group object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "",
        "createdOn": "2020-06-04T08:14:35-05:00",
        "modifiedOn": "2020-06-04T08:14:35-05:00",
        "uri": "/nodes/grp",
        "your_permissions": 64,
        "nodeType": "RootNodeType"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "group-types"
    }}>{`Group types`}</h2>
    <p>{`You can find below the roles that exists for each kind of group. There are 3 of them: account, vault and employee / client groups.`}</p>
    <br />
    <p>{`Account groups`}</p>
    <hr />
    <List values={[{
      title: 'Administrators',
      value: 'have full access to the account, vaults, folders and documents. This includes the ability to add and remove users, upgrade the account and modify the billing information.'
    }, {
      title: 'Users',
      value: 'all the users for an account; may have access to manage an account, vaults, folders and documents.'
    }, {
      title: 'Guests',
      value: 'anyone outside of the organization; may have access to view folders and manage documents.'
    }]} mdxType="List" />
    <br />
    <br />
    <p>{`Vault groups`}</p>
    <hr />
    <List values={[{
      title: 'Users',
      value: 'all the users for a vault.'
    }, {
      title: 'Vault Manager',
      value: 'has the ability to create, delete, and write folders and documents as well as manage access in the vault.'
    }, {
      title: 'Vault Full Access',
      value: 'has the ability to create, delete and write documents.'
    }, {
      title: 'Vault Full Inbox',
      value: 'has full control of the inbox.'
    }, {
      title: 'Vault Read Only',
      value: 'only has the ability to read all the documents in the vault.'
    }]} mdxType="List" />
    <br />
    <br />
    <p>{`Employee / client groups`}</p>
    <hr />
    <List values={[{
      title: 'Users',
      value: 'all the users in a firm.'
    }, {
      title: 'Firm Managers',
      value: 'this is a group that all individual Vault Manager groups are a member of.'
    }, {
      title: 'Firm Employees',
      value: 'all the employees in a firm.'
    }, {
      title: 'Vault Full Inbox',
      value: 'group of all the users that have full control of the inbox.'
    }, {
      title: 'Client Contacts',
      value: 'all the contacts related to a client.'
    }]} mdxType="List" />
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />

    <h2 {...{
      "id": "retrieve-group-information"
    }}>{`Retrieve group information`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			When browsing groups, you will need to include the path to the node to which you want to navigate to.
			<br />
			You can browse to a node, which will show you all the groups in that node, or directly to the group in that
			node, which will show you all the members in that group for the current node.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" /> Remember you can also use the children query param to discover the groups structure.
			<code>?children="numberOfChildren"</code>
		</p>
	</div>
	<Table title="Get group" rows={baseUrlGet} mdxType="Table" />
    </Row>
    <Expander title="example" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`/nodes/grp?children=2
`}</code></pre>
      <p>{`will show:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "",
        "createdOn": "2020-06-04T08:37:12-05:00",
        "modifiedOn": "2020-06-04T08:37:12-05:00",
        "uri": "/nodes/grp",
        "your_permissions": 64,
        "nodeType": "RootNodeType",
        "total_children": 1,
        "children": [
            {
                "name": "accounts",
                "createdOn": "2020-06-04T08:37:12-05:00",
                "modifiedOn": "2020-06-04T08:37:12-05:00",
                "uri": "/nodes/grp/accounts",
                "your_permissions": 64,
                "nodeType": "FolderNodeType",
                "total_children": 1,
                "children": [
                    {
                        "name": "SmartVault Account",
                        "createdOn": "2020-06-04T08:37:12-05:00",
                        "modifiedOn": "2020-06-04T08:37:12-05:00",
                        "uri": "/nodes/grp/accounts/SmartVault Account",
                        "your_permissions": 64,
                        "nodeType": "AccountNodeType"
                    }
                ]
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string (optional)',
      description: "The path to the account, node, or group that you want to view information of. If you don't specify the path, the returned data will match the RootNodeType structure."
    }]} mdxType="Attributes" />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: getGroupsCurl
    }, {
      tabTitle: 'Java',
      tabInfo: getGroupsJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: getGroupsJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: getGroupsNode
    }, {
      tabTitle: 'Perl',
      tabInfo: getGroupsPerl
    }, {
      tabTitle: 'Python',
      tabInfo: getGroupsPython
    }, {
      tabTitle: 'PHP',
      tabInfo: getGroupsPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: getGroupsRuby
    }, {
      tabTitle: 'Go',
      tabInfo: getGroupsGo
    }, {
      tabTitle: 'C#',
      tabInfo: getGroupsC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: getGroupsVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: getGroupsGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: getGroupsObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: getGroupsSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "#the-group-object"
      }}>{`group object`}</a>{`.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "",
        "createdOn": "2020-06-04T08:14:35-05:00",
        "modifiedOn": "2020-06-04T08:14:35-05:00",
        "uri": "/nodes/grp",
        "your_permissions": 64,
        "nodeType": "RootNodeType"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the path to the group is wrong.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "693476f2-8971-450d-a7b6-287cc986f3df",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "add-members-to-a-group"
    }}>{`Add members to a group`}</h2>

    <Row mdxType="Row">
	<div>
		<p>
			When adding members to a group, you will use a PUT request to /nodes/grp.
			<br />
			You will need to include the path to the node to which are modifying the groups of, the group you are adding
			the user to, and the email of the user you are adding to the group.
		</p>
	</div>
	<Table title="Add new member to group" rows={baseUrlAdd} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string',
      description: 'Path to the account or vault and the group you are adding members to.'
    }, {
      name: 'groupType',
      type: 'string',
      description: 'Group you want to insert the user into.'
    }]} mdxType="Attributes" />
    <InfoIcon mdxType="InfoIcon" />
Check <a href="#group-types">group types</a> for all the options depending on the node type.
    <br />
    <br />
    <Attributes items={[{
      name: 'email',
      type: 'string',
      description: 'Email address of the user you want to add to the group.'
    }]} mdxType="Attributes" />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: addMembersGroupCurl
    }, {
      tabTitle: 'Java',
      tabInfo: addMembersGroupJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: addMembersGroupJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: addMembersGroupNode
    }, {
      tabTitle: 'Perl',
      tabInfo: addMembersGroupPerl
    }, {
      tabTitle: 'Python',
      tabInfo: addMembersGroupPython
    }, {
      tabTitle: 'PHP',
      tabInfo: addMembersGroupPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: addMembersGroupRuby
    }, {
      tabTitle: 'Go',
      tabInfo: addMembersGroupGo
    }, {
      tabTitle: 'C#',
      tabInfo: addMembersGroupC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: addMembersGroupVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: addMembersGroupGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: addMembersGroupObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: addMembersGroupSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <Expander title="example" mdxType="Expander">
An API call to
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`https://rest.smartvault.com/nodes/grp/accounts/SmartVault account/groups/Guests/newUserEmail@gmail.com
`}</code></pre>
      <p>{`would yield the following result:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "newUserEmail@gmail.com",
        "createdOn": "2020-06-04T09:07:53-05:00",
        "modifiedOn": "2020-06-04T09:07:53-05:00",
        "uri": "/nodes/grp/accounts/SmartVault account/groups/Guests/newUserEmail@gmail.com",
        "your_permissions": 64,
        "nodeType": "UserNodeType"
    }
}
`}</code></pre>
      <p>{` `}{` `}{` `}{` `}{`"/accounts/SmartVault account" would be the {path}`}<br />{`
`}{` `}{` `}{` `}{` `}{`"Guests" is the {groupType} `}<br />{`
`}{` `}{` `}{` `}{` `}{`"`}<a parentName="p" {...{
          "href": "mailto:newUserEmail@gmail.com"
        }}>{`newUserEmail@gmail.com`}</a>{`" is the new user's {email}.`}</p>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      